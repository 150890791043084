import React from "react";
import { useMediaQuery } from "@material-ui/core";
import { SEO } from "@components";
// import { ProfileMobil } from "@components";
import { useAuth } from "@hooks";
import { useCurrentUser } from "@apollo";

import MyProfileDesktop from "@components/Profile/MyProfile/MyProfileDesktop";
import MyProfileMobile from "@components/Profile/MyProfile/MyProfileMobile";

const Profile = () => {
  const isMobile = useMediaQuery("(min-width: 1240px)");
  useAuth({ redirectIfNotLogged: true });
  const { user: currentUser } = useCurrentUser();

  return (
    <>
      <SEO />
      {isMobile ? (
        <MyProfileDesktop currentUser={currentUser} />
      ) : (
        <MyProfileMobile currentUser={currentUser} />
      )}
    </>
  );
};
export default Profile;
